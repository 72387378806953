import {
  useTranslate,
  IResourceComponentsProps,
  useMany,
  usePermissions,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  BooleanField,
  Tag,
} from "@pankod/refine-antd";
import { IUser, IVotingCenter } from "interfaces";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { data: permissionsData } = usePermissions();

  const { tableProps, sorter } = useTable<IUser>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  let votingCenterIds = tableProps?.dataSource?.reduce(
    (ids, item) => [...ids, ...item.voting_center],
    []
  );
  let votingCenterIdsSet = new Set(votingCenterIds);
  votingCenterIds = Array.from(votingCenterIdsSet);

  const { data: votingCenterData, isLoading } = useMany<IVotingCenter>({
    resource: "voting-center",
    ids: votingCenterIds,
    queryOptions: {
      enabled: votingCenterIds.length > 0,
    },
  });

  return (
    <List canCreate={permissionsData?.includes("admin")}>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="phone_no"
          key="phone_no"
          title={t("users.fields.phoneNo")}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="first_name"
          key="first_name"
          title={t("users.fields.firstName")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("first_name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="last_name"
          key="last_name"
          title={t("users.fields.lastName")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("last_name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="can_edit"
          key="can_edit"
          title={t("users.fields.canEdit")}
          render={(value) => (
            <BooleanField
                value={value}
            />
          )}
        />
        <Table.Column
          dataIndex="email"
          key="email"
          title={t("users.fields.email")}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="voting_center"
          key="voting_center"
          title={t("users.fields.votingCenters")}
          render={(value) => {
              if(isLoading) {
                return <TextField value="Loading ..." />;
              }

              return (
                <Space direction="vertical" size={4}>
                  {value.map((item: number) => (
                    <Tag key={item}>
                      {
                        votingCenterData?.data.find(
                          (center) => center.id === item,
                        )?.name
                      }
                    </Tag>
                  ))}
                </Space>
              )
            }
          }
        />
        <Table.Column<IUser>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
