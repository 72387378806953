import { useTranslate } from "@pankod/refine-core";
import {
  Checkbox,
  Edit,
  Form,
  Input,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";

import { IParty, IVoterEditProps, IVotingCenter } from "interfaces";

export const VoterEdit: React.FC<IVoterEditProps> = ({ editForm }) => {
  const t = useTranslate();

  const { saveButtonProps, id, formProps } = editForm;

  const { selectProps: votingCenterSelectProps } = useSelect<IVotingCenter>({
    resource: "voting-center",
    optionLabel: "name",
  });

  const { selectProps: partySelectProps } = useSelect<IParty>({
    resource: "party",
    optionLabel: "name",
  });

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      recordItemId={id}
      contentProps={{
        bodyStyle: {
          padding: 0,
        }
      }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("voters.fields.firstName")}
          name="first_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.lastName")}
          name="last_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.voterNo")}
          name="voter_no"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.phoneNo")}
          name="phone_no"
        >
          <Input type="number" maxLength={10} />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.age")}
          name="age"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.gender.title")}
          name="gender"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              {
                label: t("voters.fields.gender.male"),
                value: "1",
              },
              {
                label: t("voters.fields.gender.female"),
                value: "2",
              }
            ]}
          />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.spouse")}
          name="spouse_name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.parents")}
          name="parent_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.isCadre")}
          name="is_cadre"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.foreignEmployment")}
          name="is_foreign_employee"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.votingCenter")}
          name="voting_center"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...votingCenterSelectProps} />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.associatedParty")}
          name="associated_party"
        >
          <Select {...partySelectProps} />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.likelyToVoteInParliament")}
          name="likely_to_vote_in_parliament"
        >
          <Select {...partySelectProps} />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.likelyToVoteInProvince")}
          name="likely_to_vote_in_province"
        >
          <Select {...partySelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
