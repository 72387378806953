import {
    IResourceComponentsProps,
    useMany,
    useTranslate
} from "@pankod/refine-core";
import {
List,
Table,
TextField,
useTable,
BooleanField,
TagField,
Space,
EditButton,
getDefaultSortOrder,
useDrawerForm,
Drawer,
} from "@pankod/refine-antd";
import { ILeader, IParty, IVoter } from "interfaces";
import { LeaderEdit } from "./edit";
import { MemberList } from "pages/members";

export const LeaderList: React.FC<IResourceComponentsProps> = () => {
const t = useTranslate();

const { tableProps, sorter } = useTable<ILeader>({
    hasPagination: false,
    initialSorter: [
        {
        field: "id",
        order: "desc",
        },
    ],
});

const editForm = useDrawerForm<IVoter>({
    action: "edit",
});

const {
    drawerProps,
    show,
} = editForm;

const { data: partyData, isLoading: isPartyLoading } = useMany<IParty>({
    resource: "party",
    ids: []
  });

return (
    <>
        <List
            title={t('leaders.leaders')}
        >
            <Table<ILeader>
                {...tableProps}
                rowKey="voter_no"
                expandable={{
                    expandedRowRender: (record) => <MemberList members={record.members} />,
                }}
                >
                <Table.Column
                    dataIndex="serial_no"
                    key="serial_no"
                    title={t("voters.fields.serialNo")}
                    render={(value) => <TextField value={value} />}
                />
                <Table.Column
                    dataIndex="voter_no"
                    key="voter_no"
                    title={t("voters.fields.voterNo")}
                    render={(value) => <TagField value={value} />}
                />
                <Table.Column
                    dataIndex="first_name"
                    key="first_name"
                    title={t("voters.fields.firstName")}
                    render={(value) => <TextField value={value} />}
                    defaultSortOrder={getDefaultSortOrder("first_name", sorter)}
                    sorter
                />
                <Table.Column
                    dataIndex="last_name"
                    key="last_name"
                    title={t("voters.fields.lastName")}
                    render={(value) => <TextField value={value} />}
                    defaultSortOrder={getDefaultSortOrder("last_name", sorter)}
                    sorter
                />
                <Table.Column
                    dataIndex="age"
                    key="age"
                    title={t("voters.fields.age")}
                    render={(value) => <TextField value={value} />}
                    defaultSortOrder={getDefaultSortOrder("age", sorter)}
                    sorter
                />
                <Table.Column
                    dataIndex="gender"
                    key="gender"
                    title={t("voters.fields.gender.title")}
                    render={(value) => <TextField value={value} />}
                />
                <Table.Column
                    dataIndex="spouse_name"
                    key="spouse_name"
                    title={t("voters.fields.spouse")}
                    render={(value) => <TextField value={value} />}
                />
                <Table.Column
                    dataIndex="parent_name"
                    key="parent_name"
                    title={t("voters.fields.parents")}
                    render={(value) => <TextField value={value} />}
                />
                {/* <Table.Column
                    dataIndex="voting_center"
                    key="voting_center"
                    title={t("voters.fields.votingCenter")}
                    render={(value) => {
                        if(isLoading) {
                        return <TextField value="Loading ..." />;
                        }

                        return (
                        <Tag>
                            {
                            votingCenterData?.data.find(
                                (item: IVotingCenter) => item.id === value,
                            )?.name
                            }
                        </Tag>
                        )
                    }
                    }
                /> */}
                <Table.Column
                  dataIndex="associated_party"
                  key="associated_party"
                  title={t("voters.fields.associatedParty")}
                  render={(value) => {
                      if(isPartyLoading) {
                        return <TextField value="Loading ..." />;
                      }

                      return (
                        <TextField
                          value={
                            partyData?.data.find(
                              (item: IParty) => item.id === value,
                            )?.name
                          }
                        />
                      )
                    }
                  }
                />
                <Table.Column
                  dataIndex="likely_to_vote_in_parliament"
                  key="likely_to_vote_in_parliament"
                  title={t("voters.fields.likelyToVoteInParliament")}
                  render={(value) => {
                      if(isPartyLoading) {
                        return <TextField value="Loading ..." />;
                      }

                      return (
                        <TextField
                          value={
                            partyData?.data.find(
                              (item: IParty) => item.id === value,
                            )?.name
                          }
                        />
                      )
                    }
                  }
                />
                <Table.Column
                  dataIndex="likely_to_vote_in_province"
                  key="likely_to_vote_in_province"
                  title={t("voters.fields.likelyToVoteInProvince")}
                  render={(value) => {
                      if(isPartyLoading) {
                        return <TextField value="Loading ..." />;
                      }

                      return (
                        <TextField
                          value={
                            partyData?.data.find(
                              (item: IParty) => item.id === value,
                            )?.name
                          }
                        />
                      )
                    }
                  }
                />
                <Table.Column
                    dataIndex="is_cadre"
                    key="is_cadre"
                    title={t("voters.fields.isCadre")}
                    render={(value) => (
                    <BooleanField
                        value={value}
                    />
                    )}
                />
                <Table.Column
                    dataIndex="is_foreign_employee"
                    key="is_foreign_employee"
                    title={t("voters.fields.foreignEmployment")}
                    render={(value) => (
                    <BooleanField
                        value={value}
                    />
                    )}
                />
                <Table.Column<IVoter>
                    fixed="right"
                    title={t("table.actions")}
                    dataIndex="actions"
                    render={(_, record) => (
                    <Space>
                        <EditButton hideText size="small" recordItemId={record.id} onClick={() => show(record.id)}/>
                    </Space>
                    )}
                />
            </Table>
        </List>

        <Drawer
            {...drawerProps}
            width={window.innerWidth > 900 ? 700 : window.innerWidth - 50}
        >
            <LeaderEdit editForm={editForm} />
        </Drawer>
    </>
  );
};
