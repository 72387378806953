import {
  useTranslate,
  IResourceComponentsProps,
  useMany,
  CrudFilters,
  HttpError,
  usePermissions,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  TagField,
  ShowButton,
  BooleanField,
  Tag,
  Col,
  Form,
  Input,
  Row,
  Button,
  Icons,
  useSelect,
  Select,
  useDrawerForm,
  Drawer,
} from "@pankod/refine-antd";
import {
  IDashboardQuery, IMunicipality, IParty, IVoter, IVoterFilterVariables, IVotingCenter, IWard
} from "interfaces";
import { VoterEdit } from "./edit";
import { FamilyList } from "pages/families";
import { useState } from "react";

export const VoterList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { data: permissionsData } = usePermissions();
  const [query, setQuery] = useState<IDashboardQuery>({
    ward: [],
    municipality: [],
    voting_center: []
  })
  const isAdmin = permissionsData?.includes('admin') || false;
  const { selectProps: municipalitySelectProps } = useSelect<IMunicipality>({
    resource: "municipality",
    optionLabel: "name",
    queryOptions: {
        enabled: isAdmin,
    }
  });

  const { selectProps: wardSelectProps } = useSelect<IWard>({
    resource: "ward",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 20,
    },
    filters: [
        {
            field: "municipality",
            operator: "eq",
            value: query.municipality,
        },
    ],
    queryOptions: {
        enabled: isAdmin && query.municipality.length > 0,
    }
  });

  const { selectProps: votingCenterSelectProps } = useSelect<IVotingCenter>({
    resource: "voting-center",
    optionLabel: "name",
    filters: [
        {
            field: "ward",
            operator: "eq",
            value: query.ward,
        },
    ],
    queryOptions: {
      enabled: query.ward.length > 0 || !isAdmin,
    }
  });

  const editForm = useDrawerForm<IVoter>({
    action: "edit",
  });

  const {
    drawerProps,
    show,
  } = editForm;

  const { tableProps, sorter, searchFormProps } = useTable<IVoter, HttpError, IVoterFilterVariables>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },

    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { search, municipality, ward, voting_center } = params;
      console.log(params);

          filters.push(
              {
                  field: "search",
                  operator: "eq",
                  value: search,
              },
              {
                field: "municipality",
                operator: "eq",
                value: municipality,
              },
              {
                field: "ward",
                operator: "eq",
                value: ward,
            },
            {
                field: "voting_center",
                operator: "eq",
                value: voting_center,
            }
          );

      return filters;
    },
  });

  let votingCenterIds = tableProps?.dataSource?.map(
    (item) => item.voting_center
  ) ?? [];
  let votingCenterIdsSet = new Set(votingCenterIds);
  votingCenterIds = Array.from(votingCenterIdsSet);

  const { data: votingCenterData, isLoading } = useMany<IVotingCenter>({
    resource: "voting-center",
    ids: votingCenterIds,
    queryOptions: {
      enabled: votingCenterIds.length > 0,
    },
  });

  const { data: partyData, isLoading: isPartyLoading } = useMany<IParty>({
    resource: "party",
    ids: []
  });

  return (
    <>
      <Row gutter={[16, 16]}>
          <Col xs={24}>
              <Form
                {...searchFormProps}
                onValuesChange={(values) => setQuery((oldData: IDashboardQuery) => ({...oldData, ...values}))}
              >
                <Space size={10} wrap>
                  <Form.Item style={{ marginBottom: 0 }} name="search">
                      <Input
                          placeholder="Enter voter no"
                          prefix={<Icons.SearchOutlined />}
                      />
                  </Form.Item>
                  {
                    isAdmin
                      &&
                      <>
                        <Form.Item style={{ marginBottom: 0 }} name="municipality">
                          <Select
                              mode="multiple"
                              style={{ width: 250 }}
                              allowClear
                              placeholder={t("dashboard.municipalityPlaceholder")}
                              {...municipalitySelectProps}
                              onChange={(value) => console.log(value)}
                          />
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }} name="ward">
                          <Select
                              mode="multiple"
                              style={{ width: 250 }}
                              allowClear
                              placeholder={t("dashboard.wardPlaceholder")}
                              {...wardSelectProps}
                          />
                        </Form.Item>
                      </>
                  }

                  <Form.Item style={{ marginBottom: 0 }} name="voting_center">
                    <Select
                        mode="multiple"
                        style={{ width: 250 }}
                        allowClear
                        placeholder={t("dashboard.votingCenterPlaceholder")}
                        {...votingCenterSelectProps}
                    />
                  </Form.Item>
                </Space>
                <Form.Item style={{ marginTop: 10 }}>
                    <Button htmlType="submit" type="primary">
                        Filter
                    </Button>
                </Form.Item>
              </Form>
          </Col>
          <Col xs={24}>
            <List>
              <Table<IVoter>
                {...tableProps}
                rowKey="voter_no"
                expandable={{
                  expandedRowRender: (record) => <FamilyList voterNo={record.voter_no} />,
                }}
              >
                <Table.Column
                  dataIndex="serial_no"
                  key="serial_no"
                  title={t("voters.fields.serialNo")}
                  render={(value) => <TextField value={value} />}
                />
                <Table.Column
                  dataIndex="voter_no"
                  key="voter_no"
                  title={t("voters.fields.voterNo")}
                  render={(value) => <TagField value={value} />}
                />
                <Table.Column
                  dataIndex="first_name"
                  key="first_name"
                  title={t("voters.fields.firstName")}
                  render={(value) => <TextField value={value} />}
                  defaultSortOrder={getDefaultSortOrder("first_name", sorter)}
                  sorter
                />
                <Table.Column
                  dataIndex="last_name"
                  key="last_name"
                  title={t("voters.fields.lastName")}
                  render={(value) => <TextField value={value} />}
                  defaultSortOrder={getDefaultSortOrder("last_name", sorter)}
                  sorter
                />
                <Table.Column
                  dataIndex="age"
                  key="age"
                  title={t("voters.fields.age")}
                  render={(value) => <TextField value={value} />}
                  defaultSortOrder={getDefaultSortOrder("age", sorter)}
                  sorter
                />
                <Table.Column
                  dataIndex="gender"
                  key="gender"
                  title={t("voters.fields.gender.title")}
                  render={(value) => <TextField value={value} />}
                />
                <Table.Column
                  dataIndex="spouse_name"
                  key="spouse_name"
                  title={t("voters.fields.spouse")}
                  render={(value) => <TextField value={value} />}
                />
                <Table.Column
                  dataIndex="parent_name"
                  key="parent_name"
                  title={t("voters.fields.parents")}
                  render={(value) => <TextField value={value} />}
                />
                <Table.Column
                  dataIndex="voting_center"
                  key="voting_center"
                  title={t("voters.fields.votingCenter")}
                  render={(value) => {
                      if(isPartyLoading) {
                        return <TextField value="Loading ..." />;
                      }

                      return (
                        <Tag>
                          {
                            votingCenterData?.data.find(
                              (item: IVotingCenter) => item.id === value,
                            )?.name
                          }
                        </Tag>
                      )
                    }
                  }
                />
                <Table.Column
                  dataIndex="associated_party"
                  key="associated_party"
                  title={t("voters.fields.associatedParty")}
                  render={(value) => {
                      if(isPartyLoading) {
                        return <TextField value="Loading ..." />;
                      }

                      return (
                        <TextField
                          value={
                            partyData?.data.find(
                              (item: IParty) => item.id === value,
                            )?.name
                          }
                        />
                      )
                    }
                  }
                />
                <Table.Column
                  dataIndex="likely_to_vote_in_parliament"
                  key="likely_to_vote_in_parliament"
                  title={t("voters.fields.likelyToVoteInParliament")}
                  render={(value) => {
                      if(isPartyLoading) {
                        return <TextField value="Loading ..." />;
                      }

                      return (
                        <TextField
                          value={
                            partyData?.data.find(
                              (item: IParty) => item.id === value,
                            )?.name
                          }
                        />
                      )
                    }
                  }
                />
                <Table.Column
                  dataIndex="likely_to_vote_in_province"
                  key="likely_to_vote_in_province"
                  title={t("voters.fields.likelyToVoteInProvince")}
                  render={(value) => {
                      if(isLoading) {
                        return <TextField value="Loading ..." />;
                      }

                      return (
                        <TextField
                          value={
                            partyData?.data.find(
                              (item: IParty) => item.id === value,
                            )?.name
                          }
                        />
                      )
                    }
                  }
                />
                <Table.Column
                  dataIndex="is_cadre"
                  key="is_cadre"
                  title={t("voters.fields.isCadre")}
                  render={(value) => (
                    <BooleanField
                        value={value}
                    />
                  )}
                />
                <Table.Column
                  dataIndex="is_foreign_employee"
                  key="is_foreign_employee"
                  title={t("voters.fields.foreignEmployment")}
                  render={(value) => (
                    <BooleanField
                        value={value}
                    />
                  )}
                />
                <Table.Column<IVoter>
                  fixed="right"
                  title={t("table.actions")}
                  dataIndex="actions"
                  render={(_, record) => (
                    <Space>
                      <EditButton hideText size="small" recordItemId={record.voter_no} onClick={() => show(record.voter_no)}/>
                      <ShowButton hideText size="small" recordItemId={record.voter_no} />
                    </Space>
                  )}
                />
              </Table>
            </List>
          </Col>
      </Row>
      <Drawer
        {...drawerProps}
        width={window.innerWidth > 900 ? 700 : window.innerWidth - 50}
      >
        <VoterEdit editForm={editForm} />
      </Drawer>
    </>
  );
};
