import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
} from "@pankod/refine-core";
import { Show, Typography, Tag, BooleanField } from "@pankod/refine-antd";

import { IVoter } from "interfaces";

const { Title, Text } = Typography;

export const VoterShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<IVoter>();
  const { data, isLoading } = queryResult;
  const record = data?.data;


  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t("voters.fields.name")}</Title>
      <Text>{record?.first_name}</Text>

      <Title level={5}>{t("voters.fields.no")}</Title>
      <Text>
        <Tag>{record?.voter_no}</Tag>
      </Text>

      <Title level={5}>{t("voters.fields.age")}</Title>
      <Text>{record?.age}</Text>

      <Title level={5}>{t("voters.fields.gender.title")}</Title>
      <Text>{record?.gender}</Text>

      <Title level={5}>{t("voters.fields.spouse")}</Title>
      <Text>{record?.spouse_name}</Text>

      <Title level={5}>{t("voters.fields.parents")}</Title>
      <Text>{record?.parent_name}</Text>

      <Title level={5}>{t("voters.fields.isCadre")}</Title>
      <BooleanField value={record?.is_cadre} />

      <Title level={5}>{t("voters.fields.foreignEmployment")}</Title>
      <BooleanField value={record?.is_foreign_employment} />

    </Show>
  );
};
