import { useApiUrl, useCustom, useTranslate } from "@pankod/refine-core";
import { Typography } from "@pankod/refine-antd";

import { ResponsiveBar, BarDatum } from '@nivo/bar'

import { IDashboardQuery } from "interfaces";

interface ISurnameChartProps {
    query: IDashboardQuery;
}

export const SurnameChart: React.FC<ISurnameChartProps> = ({ query }: ISurnameChartProps) => {
    const t = useTranslate();
    const API_URL = useApiUrl();

    const url = `${API_URL}/analytics/surname`;
    const { data, isLoading } = useCustom<BarDatum[]>({
        url,
        method: "get",
        config: {
            query,
        },
    });

    const chartData = data?.data || [];

    return (
        <div style={{height: `${chartData.length * 30}px`}}>
            {
                isLoading
                ? "Loading ..."
                : <ResponsiveBar
                    data={chartData}
                    keys={['population']}
                    layout="horizontal"
                    indexBy="surname"
                    margin={{ top: 10, right: 0, bottom: 60, left: 70 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={{ scheme: 'nivo' }}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1.6
                            ]
                        ]
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: 'middle',
                        legendOffset: 50
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -50,
                        legendPosition: 'middle',
                        legendOffset: -50
                    }}
                    role="application"
                    ariaLabel="Surname bar chart"
                    barAriaLabel={function(e){return `Population of ${e.id}: ${e.formattedValue}`}}
                />
            }
        </div>
    );
};
