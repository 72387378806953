import { useTranslate } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Select,
  useSelect,
} from "@pankod/refine-antd"

import "react-mde/lib/styles/css/react-mde-all.css";

import { IVoter, IVoterEditProps } from "interfaces";

export const LeaderEdit: React.FC<IVoterEditProps> = ({ editForm }) => {
  const t = useTranslate();

  const { saveButtonProps, id, formProps } = editForm;

  const {
    selectProps: votersSelectProps,
    queryResult,
    defaultValueQueryResult
  } = useSelect<IVoter>({
    resource: "group/voters",
    onSearch: (value) => [
      {
        field: "search",
        operator: "eq",
        value
      }
    ]
  });

  const allOptions = [
    ...(queryResult.data?.data || []),
    ...(defaultValueQueryResult.data?.data || [])
  ];

  return (
    <Edit
        saveButtonProps={saveButtonProps}
        recordItemId={id}
        contentProps={{
            bodyStyle: {
                padding: 0,
            }
        }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("leaders.fields.leader")}
          name="leader"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...votersSelectProps}
            options = {allOptions.map((p) => ({
              label: `${p.first_name} ${p.last_name} (${p.voter_no})`,
              value: p.voter_no
            }))}
          />
        </Form.Item>
        <Form.Item
          label={t("leaders.fields.members")}
          name="members"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...votersSelectProps}
            allowClear
            mode="multiple"
            options = {allOptions.map((p) => ({
                label: `${p.first_name} ${p.last_name} (${p.voter_no})`,
                value: p.voter_no
            }))}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
