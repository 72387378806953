import React from "react";
import { useLogin } from "@pankod/refine-core";
import {
    Row,
    Col,
    AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Checkbox,
} from "@pankod/refine-antd";
import { ILoginForm } from "interfaces";

const { Title } = Typography;

export const Login: React.FC = () => {
    const [form] = Form.useForm<ILoginForm>();

    const { mutate: login } = useLogin<ILoginForm>();

    const CardTitle = (
        <Title level={3} className="title">
            Sign in to your account
        </Title>
    );

    return (
        <AntdLayout>
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22} md={15} lg={10} xl={8}>
                    <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
                        <Form<ILoginForm>
                            layout="vertical"
                            form={form}
                            onFinish={(values) => {
                                login(values);
                            }}
                            requiredMark={false}
                            initialValues={{
                                remember: true,
                            }}
                        >
                            <Form.Item
                                name="phone_no"
                                label="Phone no."
                                rules={[{ required: true }]}
                            >
                                <Input
                                    size="large"
                                    placeholder="phone no."
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[{ required: true }]}
                                style={{ marginBottom: "12px" }}
                            >
                                <Input
                                    type="password"
                                    placeholder="●●●●●●●●"
                                    size="large"
                                />
                            </Form.Item>
                            <div style={{ marginBottom: "12px" }}>
                                <Form.Item
                                    name="remember"
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Checkbox
                                        style={{
                                            fontSize: "12px",
                                        }}
                                    >
                                        Remember me
                                    </Checkbox>
                                </Form.Item>
                            </div>
                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                                block
                            >
                                Sign in
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </AntdLayout>
    );
};
