import { AuthProvider } from "@pankod/refine-core";
import axios from "axios";
import { axiosInstance } from "axiosInstance";
import { API_BASE_URL, TOKEN_KEY, TOKEN_REFRESH_KEY, USER_DETAILS_KEY } from "config";

export interface IError {
  code: string;
  config: string;
  remember: boolean;
}

export const authProvider: AuthProvider = {
  login: async ({ phone_no, password }) => {
    try{
      const response = await axios.post(`${API_BASE_URL}/login`, {phone_no, password});
      if (response.status === 200) {
        const data = response.data?.details
        const token = data.access_token
        sessionStorage.setItem(TOKEN_KEY, token);
        localStorage.setItem(TOKEN_REFRESH_KEY, data.refresh_token);
        localStorage.setItem(USER_DETAILS_KEY, JSON.stringify(data));
        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        return Promise.resolve(data);
      }
      if(response.status === 400) {
        return Promise.reject(new Error(response.data?.message ?? "Invalid login details"));
      }
    } catch(error: any) {
      return Promise.reject(new Error(error?.response?.data.message));
    }
    return Promise.reject(new Error("username: admin, password: admin"));
  },
  logout: () => {
    sessionStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_REFRESH_KEY);
    localStorage.removeItem(USER_DETAILS_KEY);
    return Promise.resolve();
  },
  checkError: (error) => {
    if(error.response.status === 401) {
      // eslint-disable-next-line no-restricted-globals
      return Promise.reject(location.pathname);
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = sessionStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => {
    const user = JSON.parse(localStorage.getItem(USER_DETAILS_KEY) ?? "{}");
    const role = user?.is_superuser ? 'admin' : 'user';
    return Promise.resolve([role])
  },
  getUserIdentity: async () => {
    const user = localStorage.getItem(USER_DETAILS_KEY);
    if (!user) {
      return Promise.reject();
    }

    return Promise.resolve(JSON.parse(user));
  },
};
