import { useApiUrl, useCustom, useTranslate } from "@pankod/refine-core";
import { Card, Col, Row, Space, Statistic } from "@pankod/refine-antd";


import { IDashboardQuery, ISummaryChart } from "interfaces";
import { UsergroupAddOutlined, UserSwitchOutlined } from "@ant-design/icons";

interface IGenderChartProps {
    query: IDashboardQuery;
}

export const SummaryChart: React.FC<IGenderChartProps> = ({ query }: IGenderChartProps) => {
    const t = useTranslate();
    const API_URL = useApiUrl();

    const url = `${API_URL}/analytics/summary`;
    const { data } = useCustom<ISummaryChart>({
        url,
        method: "get",
        config: {
            query,
        },
    });

    return (
        <Row gutter={[15, 15]}>
            {
                data?.data?.map((item) => (
                    <Col lg={24} sm={12} xs={24} key={item.id}>
                        <Card
                            bordered={false}
                        >
                            <Space
                                align="center"
                                style={{
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}
                            >
                                <Statistic
                                    title={
                                        item.id === 'total_voters' ?
                                            t('dashboard.summary.totalVoters')
                                            : t('dashboard.summary.totalCadres')

                                    }
                                    value={item.value}
                                    valueStyle={{
                                        fontWeight: 'bold',
                                        fontSize: '20pt'
                                    }}
                                />
                                {
                                    item.id === 'total_voters'
                                        ?
                                            <UsergroupAddOutlined
                                                style={{
                                                    fontSize: '30pt'
                                                }}
                                            />
                                        :
                                            <UserSwitchOutlined
                                                style={{
                                                    fontSize: '30pt'
                                                }}
                                            />
                                }
                            </Space>
                        </Card>
                    </Col>
                ))
            }
        </Row>
    );
};
