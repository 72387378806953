import {
    useTranslate
} from "@pankod/refine-core";
import {
List,
Table,
TextField,
useTable,
BooleanField,
TagField,
} from "@pankod/refine-antd";
import { IFamilyListProps, IVoter } from "interfaces";

export const FamilyList: React.FC<IFamilyListProps> = ({ voterNo }) => {
const t = useTranslate();

const { tableProps } = useTable<IVoter>({
    resource: `group/family`,
    hasPagination: false,
    initialSorter: [
        {
        field: "id",
        order: "desc",
        },
    ],
    initialFilter: [
        {
            field: 'voter_no',
            operator: 'eq',
            value: voterNo
        }
    ]
});

return (
    <List
        title={t('families.families')}
        canCreate={false}
    >
        <Table<IVoter>
            {...tableProps}
            rowKey="voter_no"
            >
            <Table.Column
                dataIndex="serial_no"
                key="serial_no"
                title={t("voters.fields.serialNo")}
                render={(value) => <TextField value={value} />}
            />
            <Table.Column
                dataIndex="voter_no"
                key="voter_no"
                title={t("voters.fields.voterNo")}
                render={(value) => <TagField value={value} />}
            />
            <Table.Column
                dataIndex="first_name"
                key="first_name"
                title={t("voters.fields.firstName")}
                render={(value) => <TextField value={value} />}
            />
            <Table.Column
                dataIndex="last_name"
                key="last_name"
                title={t("voters.fields.lastName")}
                render={(value) => <TextField value={value} />}
            />
            <Table.Column
                dataIndex="age"
                key="age"
                title={t("voters.fields.age")}
                render={(value) => <TextField value={value} />}
            />
            <Table.Column
                dataIndex="gender"
                key="gender"
                title={t("voters.fields.gender.title")}
                render={(value) => <TextField value={value} />}
            />
            <Table.Column
                dataIndex="spouse_name"
                key="spouse_name"
                title={t("voters.fields.spouse")}
                render={(value) => <TextField value={value} />}
            />
            <Table.Column
                dataIndex="parent_name"
                key="parent_name"
                title={t("voters.fields.parents")}
                render={(value) => <TextField value={value} />}
            />
            <Table.Column
                dataIndex="is_cadre"
                key="is_cadre"
                title={t("voters.fields.isCadre")}
                render={(value) => (
                    <BooleanField
                        value={value}
                    />
                )}
            />
            <Table.Column
                dataIndex="is_foreign_employee"
                key="is_foreign_employee"
                title={t("voters.fields.foreignEmployment")}
                render={(value) => (
                    <BooleanField
                        value={value}
                    />
                )}
            />
            </Table>
        </List>
    );
};
