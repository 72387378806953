import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Checkbox,
  Edit,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";

import { IDashboardQuery, IMunicipality, IUser, IVotingCenter, IWard } from "interfaces";
import { useState } from "react";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, queryResult } = useForm<IUser>();

  const [query, setQuery] = useState<IDashboardQuery>({
    ward: [],
    municipality: [],
    voting_center: []
  })


  const { selectProps: municipalitySelectProps } = useSelect<IMunicipality>({
    resource: "municipality",
    optionLabel: "name",
  });

  const { selectProps: wardSelectProps } = useSelect<IWard>({
      resource: "ward",
      optionLabel: "name",
      pagination: {
          current: 1,
          pageSize: 20,
      },
      filters: [
          {
              field: "municipality",
              operator: "eq",
              value: query.municipality,
          },
      ],
      queryOptions: {
          enabled: query.municipality.length > 0,
      }
  });


  const { selectProps: votingCenterSelectProps } = useSelect<IVotingCenter>({
    resource: "voting-center",
    optionLabel: "name",
    defaultValue: queryResult?.data?.data?.voting_center,
    filters: [
        {
            field: "ward",
            operator: "eq",
            value: query.ward,
        },
    ],
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onValuesChange={(values) => setQuery((oldData: IDashboardQuery) => ({...oldData, ...values}))}
      >
      <Form.Item
          label={t("users.fields.firstName")}
          name="first_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("users.fields.lastName")}
          name="last_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("users.fields.email")}
          name="email"
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          label={t("users.fields.canEdit")}
          name="can_edit"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label={t("users.fields.municipality")}
          name="municipality"
        >
          <Select mode="multiple" allowClear {...municipalitySelectProps} />
        </Form.Item>
        <Form.Item
          label={t("users.fields.ward")}
          name="ward"
        >
          <Select mode="multiple" allowClear {...wardSelectProps} />
        </Form.Item>
        <Form.Item
          label={t("users.fields.votingCenters")}
          name="voting_center"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select mode="multiple" allowClear {...votingCenterSelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
