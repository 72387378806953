import {
  useTranslate,
  IResourceComponentsProps,
  usePermissions,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  ImageField,
} from "@pankod/refine-antd";
import { IParty } from "interfaces";
import { MEDIA_BASE_URL } from "config";

export const PartyList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { data: permissionsData } = usePermissions();

  const { tableProps, sorter } = useTable<IParty>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="symbol_url"
          key="symbol_url"
          title={t("parties.fields.logo")}
          render={(value) => <ImageField value={`${MEDIA_BASE_URL}${value}`} width={60} />}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title={t("parties.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column<IParty>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {
                permissionsData?.includes("admin") &&
                <>
                  <EditButton hideText size="small" recordItemId={record.id} />
                  <DeleteButton hideText size="small" recordItemId={record.id} />
                </>
              }
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
