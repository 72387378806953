import React from "react";

import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  Layout,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-antd";

import routerProvider from "@pankod/refine-react-router-v6";
import { RefineKbarProvider } from "@pankod/refine-kbar";
import { useTranslation } from "react-i18next";
import "styles/antd.less";
import { VoterCreate, VoterList, VoterShow } from "pages/voters";
import { OffLayoutArea } from "components/offLayoutArea";
import { Header } from "components/layout";
import { authProvider } from "./authProvider";
import { VotingCenterCreate, VotingCenterEdit, VotingCenterList } from "pages/voting-center";
import { WardEdit, WardList } from "pages/wards";
import { CadreList } from "pages/cadres";
import { PartyEdit, PartyList } from "pages/parties";
import { API_BASE_URL, USER_DETAILS_KEY } from "config";
import { DashboardPage } from "pages/dashboard";
import { UserCreate, UserEdit, UserList } from "pages/users";
import { Login } from "pages/login";
import { axiosInstance } from "axiosInstance";
import { restDataProvider } from "dataProvider";
import { LeaderCreate, LeaderList } from "pages/leaders";
import { ApartmentOutlined, AuditOutlined, FlagOutlined, TeamOutlined, UserAddOutlined, UsergroupAddOutlined, UserSwitchOutlined } from "@ant-design/icons";

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <RefineKbarProvider>
      <Refine
        notificationProvider={notificationProvider}
        accessControlProvider={{
          can: async ({ resource, action, params }) => {
            const user = JSON.parse(localStorage.getItem(USER_DETAILS_KEY) || "{}")
            const isAdmin = user.is_superuser;

            if(isAdmin) {
              return Promise.resolve({ can: true })
            } else if(resource === 'dashboard' || resource === 'voters') {
              return Promise.resolve({ can: true })
            }

            return Promise.resolve({ can: false })
          },
        }}
        Layout={Layout}
        ReadyPage={ReadyPage}
        catchAll={<ErrorComponent />}
        routerProvider={routerProvider}
        dataProvider={restDataProvider(API_BASE_URL, axiosInstance)}
        authProvider={authProvider}
        LoginPage={Login}
        DashboardPage={DashboardPage}
        resources={[
          {
            name: "users",
            list: UserList,
            create: UserCreate,
            edit: UserEdit,
            icon: <UserAddOutlined />
          },
          {
            name: "voters",
            list: VoterList,
            create: VoterCreate,
            show: VoterShow,
            icon: <TeamOutlined />
          },
          {
            name: "group/leader",
            list: LeaderList,
            create: LeaderCreate,
            icon: <UserSwitchOutlined />,
            options: {
              label: t('leaders.leaders')
            }
          },
          {
            name: "voting-center",
            list: VotingCenterList,
            create: VotingCenterCreate,
            edit: VotingCenterEdit,
            icon: <AuditOutlined />,
            canDelete: false,
            options: {
              label: t("votingCenter.votingCenters")
            }
          },
          {
            name: "ward",
            list: WardList,
            edit: WardEdit,
            icon: <ApartmentOutlined />,
            options: {
              label: t("wards.wards")
            }
          },
          {
            name: "cadres",
            list: CadreList,
            icon: <UsergroupAddOutlined />,
            options: {
              label: t("cadres.cadres")
            }
          },
          {
            name: "party",
            list: PartyList,
            edit: PartyEdit,
            icon: <FlagOutlined />,
            options: {
              label: t("parties.parties")
            }
          }
        ]}
        OffLayoutArea={OffLayoutArea}
        i18nProvider={i18nProvider}
        Header={Header}
      />
    </RefineKbarProvider>
  );
}

export default App;
