import dataProvider from "@pankod/refine-simple-rest";
import { API_BASE_URL } from "config";
import {
    CrudFilters,
    CrudOperators,
    CrudSorting,
    DataProvider,
} from "@pankod/refine-core";
import { AxiosInstance } from "axios";
import { stringify } from "query-string";


const mapOperator = (operator: CrudOperators): string => {
    switch (operator) {
        case "eq":
            return "";
        case "contains":
            return "__contains";
        default:
            throw new Error(`Operator ${operator} is not supported`);
    }
};

const generateFilter = (filters?: CrudFilters) => {
    const queryFilters: { [key: string]: string } = {};
    if (filters) {
        // eslint-disable-next-line
        filters.map((filter: any) => {
            if (
                filter.operator !== "or" &&
                filter.operator !== "and" &&
                "field" in filter
            ) {
                const { field, operator, value } = filter;

                const mappedOperator = mapOperator(operator);
                queryFilters[`${field}${mappedOperator}`] = value;
            }
        });
    }

    return queryFilters;
};


const generateSort = (sort?: CrudSorting) => {
    if (sort && sort.length > 0) {
        let ordering: string[] = [];

        // eslint-disable-next-line array-callback-return
        sort.map((item) => {
            if (item.order === "asc") {
                ordering.push(item.field);
            } else {
                ordering.push(`-${item.field}`);
            }
        });

        return {
            ordering
        };
    }

    return;
};


export const restDataProvider = (
    apiUrl: string = API_BASE_URL,
    axios: AxiosInstance
): DataProvider => {
    return {
        ...dataProvider(apiUrl, axios),

        getList: async ({
            resource,
            hasPagination = true,
            pagination = { current: 1, pageSize: 10 },
            filters,
            sort,
        }) => {
            const url = `${apiUrl}/${resource}`;

            const { current = 1, pageSize = 10 } = pagination ?? {};

            const queryFilters = generateFilter(filters);

            const query: {
                limit: number;
                offset: number;
                ordering: string;
            } = {
                offset: (current - 1) * pageSize,
                limit: pageSize,
                ordering: ""
            };

            const generatedSort = generateSort(sort);
            if (generatedSort) {
                const { ordering } = generatedSort;
                query.ordering = ordering.join(",");
            }

            const { data } = await axios.get(
                `${url}?${stringify(query)}&${stringify(queryFilters)}`,
            );

            return {
                data: data?.results,
                total:
                    data?.count || undefined,
            };
        },

        getMany: async ({ resource, ids }) => {
            const { data } = await axios.get(
                `${apiUrl}/${resource}?${stringify({ id: ids })}`,
            );

            return {
                data,
            };
        },
    }
};
