import {
  useTranslate,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  TagField,
  BooleanField,
} from "@pankod/refine-antd";
import { IVoter } from "interfaces";

export const CadreList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<IVoter>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  return (
    <List>
      <Table {...tableProps} rowKey="voter_no">
        <Table.Column
          dataIndex="serial_no"
          key="serial_no"
          title={t("voters.fields.serialNo")}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="first_name"
          key="first_name"
          title={t("voters.fields.firstName")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("first_name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="voter_no"
          key="voter_no"
          title={t("voters.fields.voterNo")}
          render={(value) => <TagField value={value} />}
          defaultSortOrder={getDefaultSortOrder("voter_no", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="age"
          key="age"
          title={t("voters.fields.age")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("age", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="gender"
          key="gender"
          title={t("voters.fields.gender.title")}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="spouse_name"
          key="spouse_name"
          title={t("voters.fields.spouse")}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="parent_name"
          key="parent_name"
          title={t("voters.fields.parents")}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="is_foreign_employee"
          key="is_foreign_employee"
          title={t("voters.fields.foreignEmployment")}
          render={(value) => (
            <BooleanField
                value={value}
            />
          )}
          defaultSortOrder={getDefaultSortOrder("is_foreign_employee", sorter)}
          sorter
        />
      </Table>
    </List>
  );
};
