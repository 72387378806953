import { Row, Col, Card, Typography, useSelect, Select, Form, useForm, Collapse, Space } from "@pankod/refine-antd";
import { usePermissions } from "@pankod/refine-core";
import { AgeChart, GenderChart, PartyChart, SummaryChart } from "components/dashboard";
import { SurnameChart } from "components/dashboard/SurnameChart";
import { IDashboardQuery, IMunicipality, IVotingCenter, IWard } from "interfaces";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export const DashboardPage: React.FC = () => {
    const { t } = useTranslation();
    const { data: permissionsData } = usePermissions();
    const isAdmin = permissionsData?.includes('admin') || false;

    const [query, setQuery] = useState<IDashboardQuery>({
        ward: [],
        municipality: [],
        voting_center: []
    })

    const {formProps} = useForm<IDashboardQuery>()

    const { selectProps: municipalitySelectProps } = useSelect<IMunicipality>({
        resource: "municipality",
        optionLabel: "name",
        queryOptions: {
            enabled: isAdmin,
        }
    });

    const { selectProps: wardSelectProps } = useSelect<IWard>({
        resource: "ward",
        optionLabel: "name",
        pagination: {
            current: 1,
            pageSize: 20,
        },
        filters: [
            {
                field: "municipality",
                operator: "eq",
                value: query.municipality,
            },
        ],
        queryOptions: {
            enabled: isAdmin && query.municipality.length > 0,
        }
    });

    const { selectProps: votingCenterSelectProps } = useSelect<IVotingCenter>({
        resource: "voting-center",
        optionLabel: "name",
        filters: [
            {
                field: "ward",
                operator: "eq",
                value: query.ward,
            },
        ],
        queryOptions: {
          enabled: query.ward.length > 0 || !isAdmin,
        }
    });

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Form
                    {...formProps}
                    layout="inline"
                    onValuesChange={(values) => setQuery((oldData: IDashboardQuery) => ({...oldData, ...values}))}
                >
                    <Space wrap>
                    {
                        isAdmin &&
                        <>
                            <Form.Item
                                name="municipality"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder={t("dashboard.municipalityPlaceholder")}
                                    style={{ width: 250 }}
                                    {...municipalitySelectProps}
                                />
                            </Form.Item>
                            <Form.Item
                                name="ward"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder={t("dashboard.wardPlaceholder")}
                                    style={{ width: 250 }}
                                    {...wardSelectProps}
                                />
                            </Form.Item>
                        </>
                    }
                        <Form.Item
                            name="voting_center"
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder={t("dashboard.votingCenterPlaceholder")}
                                style={{ width: 250 }}
                                {...votingCenterSelectProps}
                            />
                        </Form.Item>
                    </Space>
                </Form>
            </Col>
            <Col xs={24}>
                <Row gutter={[16, 16]}>
                    <Col lg={6} md={24} xs={24}>
                        <SummaryChart query={query} />
                    </Col>
                    <Col lg={9} md={12} xs={24}>
                        <Card>
                            <GenderChart query={query} />
                        </Card>
                    </Col>
                    <Col lg={9} md={12} xs={24}>
                        <Card>
                            <AgeChart query={query} />
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col xs={24}>
                <Collapse>
                    <Collapse.Panel
                        header={
                            <Text strong>
                                {t("dashboard.surname.title")}
                            </Text>
                        } key="1"
                    >
                        <SurnameChart query={query} />
                    </Collapse.Panel>
                </Collapse>
            </Col>
            <Col xs={24}>
                <Card
                    bodyStyle={{
                        height: 550,
                    }}
                    title={
                        <Text strong>{t("dashboard.party.title")}</Text>
                    }
                >
                    <PartyChart query={query} />
                </Card>
            </Col>
        </Row>
    );
};
