import { useApiUrl, useCustom, useTranslate } from "@pankod/refine-core";
import { Typography } from "@pankod/refine-antd";

import { ResponsiveBar, BarDatum } from '@nivo/bar'

import { IDashboardQuery } from "interfaces";

interface IPartyChartProps {
    query: IDashboardQuery;
}

export const PartyChart: React.FC<IPartyChartProps> = ({ query }: IPartyChartProps) => {
    const t = useTranslate();
    const API_URL = useApiUrl();

    const url = `${API_URL}/analytics/party`;
    const { data, isLoading } = useCustom<BarDatum[]>({
        url,
        method: "get",
        config: {
            query,
        },
    });

    const chartData = data?.data || [];

    return (
        <div style={{height: '500px'}}>
            {
                isLoading
                ? "Loading ..."
                : <ResponsiveBar
                    data={chartData}
                    keys={[
                        "associated_party",
                        "likely_to_vote_in_province",
                        "likely_to_vote_in_parliament"
                    ]}
                    indexBy="party"
                    margin={{ top: 10, right: 0, bottom: 60, left: 70 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={{ scheme: 'nivo' }}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1.6
                            ]
                        ]
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 20,
                        legendPosition: 'middle',
                        legendOffset: 50
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: 'middle',
                        legendOffset: -60
                    }}
                    role="application"
                    ariaLabel="Surname bar chart"
                    barAriaLabel={function(e){return `Population of ${e.id}: ${e.formattedValue}`}}
                />
            }
        </div>
    );
};
