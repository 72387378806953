import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd"

import "react-mde/lib/styles/css/react-mde-all.css";

import { IVotingCenter, IWard } from "interfaces";

export const VotingCenterCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps } = useForm<IVotingCenter>();

  const { selectProps: categorySelectProps } = useSelect<IWard>({
    resource: "ward",
    optionLabel: "name",
});

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("votingCenter.fields.name")}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
            label={t("votingCenter.fields.ward")}
            name="ward"
            rules={[
                {
                    required: true,
                },
            ]}
        >
            <Select {...categorySelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
