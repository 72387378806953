import {
  useTranslate,
  IResourceComponentsProps,
  usePermissions,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
} from "@pankod/refine-antd";
import { IWard } from "interfaces";

export const WardList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { data: permissionsData } = usePermissions();

  const { tableProps, sorter } = useTable<IWard>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title={t("wards.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="ward_no"
          key="ward_no"
          title={t("wards.fields.wardNo")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("ward_no", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="municipality_name"
          key="municipality_name"
          title={t("wards.fields.municipalityName")}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<IWard>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {
                permissionsData?.includes("admin") &&
                <EditButton hideText size="small" recordItemId={record.id} />
              }
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
