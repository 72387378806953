import { useApiUrl, useCustom, useTranslate } from "@pankod/refine-core";
import { Typography } from "@pankod/refine-antd";

import { ResponsivePie } from '@nivo/pie'

import { IGenderChart, IDashboardQuery } from "interfaces";

interface IGenderChartProps {
    query: IDashboardQuery;
}

export const GenderChart: React.FC<IGenderChartProps> = ({ query }: IGenderChartProps) => {
    const t = useTranslate();
    const API_URL = useApiUrl();

    const url = `${API_URL}/analytics/gender`;
    const { data, isLoading } = useCustom<IGenderChart[]>({
        url,
        method: "get",
        config: {
            query,
        },
    });

    const chartData = data?.data || [];

    return (
        <div>
            <div>
                <div>
                    <Typography.Title level={3}>
                        {t("dashboard.gender.title")}
                    </Typography.Title>
                </div>
            </div>

            <div style={{height: "300px"}}>
                {
                    isLoading
                    ? "Loading ..."
                    : <ResponsivePie
                        data={chartData}
                        margin={{ top: 0, right: 0, bottom: 50, left: 0 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.2
                                ]
                            ]
                        }}
                        enableArcLinkLabels={false}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    2
                                ]
                            ]
                        }}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: {
                                    id: 'male'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'female'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'others'
                                },
                                id: 'lines'
                            },
                        ]}
                        legends={[
                            {
                                anchor: 'bottom',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 40,
                                itemsSpacing: 0,
                                itemWidth: 80,
                                itemHeight: 18,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 18,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                }
            </div>
        </div>
    );
};
