import axios, { AxiosError, AxiosInstance } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import { API_BASE_URL, TOKEN_KEY, TOKEN_REFRESH_KEY, USER_DETAILS_KEY } from "config";

function getAuthToken(token = sessionStorage.getItem(TOKEN_KEY)) {
  return `Bearer ${token}`;
}

export const axiosInstance: AxiosInstance = axios.create();

const refreshAuthLogic = async (failedRequest: AxiosError) => {
  const refreshToken = localStorage.getItem(TOKEN_REFRESH_KEY);
  if (refreshToken) {

    try {
      const { data } = await axios
      .post(`${API_BASE_URL}/token/refresh/`, {
        refresh: refreshToken,
      });
      const { access } = data;
      sessionStorage.setItem(TOKEN_KEY, access);

      axiosInstance.defaults.headers.common["Authorization"] = getAuthToken(access);
      return Promise.resolve();
    } catch {
      sessionStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(TOKEN_REFRESH_KEY);
      localStorage.removeItem(USER_DETAILS_KEY);
      return Promise.reject(failedRequest);
    }
  }
  sessionStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_REFRESH_KEY);
  localStorage.removeItem(USER_DETAILS_KEY);
  return Promise.reject(failedRequest);
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);
