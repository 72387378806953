import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd"

import "react-mde/lib/styles/css/react-mde-all.css";

import { ILeader, IVoter } from "interfaces";

export const LeaderCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps } = useForm<ILeader>({
    resource: 'group/leader'
  });

  const {
    selectProps: votersSelectProps,
    queryResult,
    defaultValueQueryResult
  } = useSelect<IVoter>({
    resource: "group/voters",
    onSearch: (value) => [
      {
        field: "search",
        operator: "eq",
        value
      }
    ]
  });

  const allOptions = [
    ...(queryResult.data?.data || []),
    ...(defaultValueQueryResult.data?.data || [])
  ];

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("leaders.fields.leader")}
          name="leader"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...votersSelectProps}
            options = {allOptions.map((p) => ({
              label: `${p.first_name} ${p.last_name} (${p.voter_no})`,
              value: p.voter_no
            }))}
          />
        </Form.Item>
        <Form.Item
          label={t("leaders.fields.members")}
          name="members"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...votersSelectProps}
            allowClear
            mode="multiple"
            options = {allOptions.map((p) => ({
              label: `${p.first_name} ${p.last_name} (${p.voter_no})`,
              value: p.voter_no
            }))}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
