import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Checkbox,
  Create,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd"

import "react-mde/lib/styles/css/react-mde-all.css";

import { IParty, IVoter, IVotingCenter } from "interfaces";

export const VoterCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps } = useForm<IVoter>();

  const { selectProps: votingCenterSelectProps } = useSelect<IVotingCenter>({
    resource: "voting-center",
    optionLabel: "name",
  });

  const { selectProps: associatedPartySelectProps } = useSelect<IParty>({
    resource: "party",
    optionLabel: "name",
  });

  const { selectProps: likelyToVoteInParliamentSelectProps } = useSelect<IParty>({
    resource: "party",
    optionLabel: "name",
  });

  const { selectProps: likelyToVoteInProvinceSelectProps } = useSelect<IParty>({
    resource: "party",
    optionLabel: "name",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("voters.fields.serialNo")}
          name="serial_no"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.firstName")}
          name="first_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.lastName")}
          name="last_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.voterNo")}
          name="voter_no"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.phoneNo")}
          name="phone_no"
        >
          <Input type="number" maxLength={10} />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.age")}
          name="age"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.gender.title")}
          name="gender"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              {
                label: t("voters.fields.gender.male"),
                value: "1",
              },
              {
                label: t("voters.fields.gender.female"),
                value: "2",
              }
            ]}
          />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.spouse")}
          name="spouse_name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.parents")}
          name="parent_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.isCadre")}
          name="is_cadre"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.foreignEmployment")}
          name="is_foreign_employee"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.votingCenter")}
          name="voting_center"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...votingCenterSelectProps} />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.associatedParty")}
          name="associated_party"
        >
          <Select {...associatedPartySelectProps} />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.likelyToVoteInParliament")}
          name="likely_to_vote_in_parliament"
        >
          <Select {...likelyToVoteInParliamentSelectProps} />
        </Form.Item>
        <Form.Item
          label={t("voters.fields.likelyToVoteInProvince")}
          name="likely_to_vote_in_province"
        >
          <Select {...likelyToVoteInProvinceSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
